:root {
  --primaryColor005: rgba(50, 4, 173, 0.05);
  --primaryColor010: rgba(50, 4, 173, 0.1);
  --primaryColor015: rgba(50, 4, 173, 0.15);
  --primaryColor030: rgba(50, 4, 173, 0.3);
  --primaryColor060: rgba(50, 4, 173, 0.6);
  --primaryColor080: rgba(50, 4, 173, 0.8);
  --primaryColor090: rgba(50, 4, 173, 0.9);
  --primaryColor100: rgba(50, 4, 173, 1);

  --white-color: rgba(255, 255, 255, 1);
  --white-color-x: rgba(215, 220, 225, 1);
  --white-color-z: rgba(240, 240, 255, 1);
  --black-color: rgba(0, 0, 68, 1);

  --red-color: rgba(178, 13, 51, 1);
  --pink-color: rgba(235, 90, 145, 1);
  --pink-background-color: rgba(255, 238, 240, 1);

  --green-color: rgba(4, 84, 65, 1);
  --green-light-color: rgba(40, 190, 80, 1);
  --green-background-color: rgba(227, 252, 237, 1);

  --yellow-color: rgba(240, 190, 90, 1);
  --orange-color: rgba(242, 162, 2, 1);
  --orange-background-color: rgba(255, 235, 180, 1);

  --cyan-color: rgba(5, 115, 130, 1);
  --purple-light-color:  rgba(51, 51, 105, 1);

  --positiveColor: var(--green-light-color);
  --neutralColor: var(--yellow-color);
  --warningColor: var(--orange-color);
  --passiveColor: var(--white-color-x);
  --infoColor: var(--cyan-color);
  --progressColor: var(--cyan-color);

  --textColor005: rgba(46, 47, 56, 0.05);
  --textColor010: rgba(46, 47, 56, 0.1);
  --textColor015: rgba(46, 47, 56, 0.15);
  --textColor020: rgba(46, 47, 56, 0.15);
  --textColor030: rgba(46, 47, 56, 0.3);
  --textColor060: rgba(46, 47, 56, 0.6);
  --textColor070: rgba(46, 47, 56, 0.7);
  --textColor080: rgba(46, 47, 56, 0.8);
  --textColor100: rgba(46, 47, 56, 1);

  --whiteShadowColor010: rgba(255, 255, 255, 0.1);
  --whiteShadowColor025: rgba(255, 255, 255, 0.25);
  --whiteShadowColor050: rgba(255, 255, 255, 0.5);
  --whiteShadowColor080: rgba(255, 255, 255, 0.8);
  --whiteShadowColor100: var(--white-color);

  --blackShadowColor005: rgba(0, 0, 68, 0.05);
  --blackShadowColor010: rgba(0, 0, 68, 0.1);
  --blackShadowColor015: rgba(0, 0, 68, 0.15);
  --blackShadowColor020: rgba(0, 0, 68, 0.2);
  --blackShadowColor025: rgba(0, 0, 68, 0.25);
  --blackShadowColor050: rgba(0, 0, 68, 0.5);
  --blackShadowColor090: rgba(0, 0, 68, 0.9);
  --blackShadowColor100: var(--black-color);

  --mainBackgroundColor: rgba(246, 246, 248, 1);
  --lightSurfaceColor: var(--whiteShadowColor100);
  --darkSurfaceColor: var(--blackShadowColor100);

  --navigationColor--light: var(--blackShadowColor100);
  --navigationActiveColor--light: var(--primaryColor100);
  --navigationBackgroundColor--light: var(--lightSurfaceColor);
  --navigationHoverBackgroundColor--light: var(--mainBackgroundColor);
  --navigationActiveBackgroundColor--light: var(--white-color-z);

  --navigationColor--dark: var(--whiteShadowColor100);
  --navigationActiveColor--dark: var(--primaryColor100);
  --navigationBackgroundColor--dark: var(--darkSurfaceColor);
  --navigationHoverBackgroundColor--dark: var(--purple-light-color);
  --navigationActiveBackgroundColor--dark: var(--whiteShadowColor100);

  --page-header-height: 60px;
  --tab-menu-header-height: 50px;
  --navigation-width: 110px;
  --infoView-width: 425px;

  --button-light-box-shadow: none;
  --button-dark-box-shadow: 0 4px 6px var(--primaryColor015);
  --button-ultra-light-background: var(--blackShadowColor005);
  --button-light-background: var(--blackShadowColor010);
  --button-dark-background: var(--blackShadowColor020);

  --error-text-color: var(--pink-color);
  --error-text-background-color: var(--pink-light-background-color);
  --error-input-border-color: var(--pink-color);
  --error-button-background-color: var(--pink-color);
}

@media only screen and (max-width: 992px) {
  :root {
    --page-header-height: 80px;
  }
}

html {
  font-size: 15px;
}

body {
  color: var(--blackShadowColor100);
  height: 100vh;
  min-width: 576px;
  font-size: 1rem;
  margin: 0;
  overflow: hidden;
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

*:focus {
  outline: none !important;
}

a {
  color: var(--primaryColor100);
  text-decoration: none;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  background: var(--button-light-background);
}

.p-button:focus,
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: var(--button-light-box-shadow);
}
